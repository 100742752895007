import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "United Kingdom",
  "subtitle": "April 2011",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "london"
    }}>{`London`}</h2>
    <h3 {...{
      "id": "getting-around"
    }}>{`Getting around`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tfl.gov.uk/travel-information/visiting-london/visitor-oyster-card"
        }}>{`Oyster card`}</a>{` needed to take the tube`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jAWPgmkHYF5ewKQP7"
        }}>{`Ben’s Cookies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/J1MUWxjKxJvMF7aB6"
        }}>{`Laduree`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uibnQ3MwUAVyr5ZV9"
        }}>{`The Orangery`}</a>{` - Afternoon tea around Kensington Palace`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Fish_and_chips"
        }}>{`Fish & chips`}</a>{` - Can be had at many bars`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jEmUpWwmB62ZwiHd9"
        }}>{`Big Ben`}</a>{` - Hard to miss this iconic clocktower`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3mTxLBCSSLHkwuJN7"
        }}>{`Buckingham Palace`}</a>{` - Changing of the guard`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3my3k1k59UaisodN6"
        }}>{`Harrods`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/x8v4g1SPqLqcgdTP8"
        }}>{`HMS Belfast`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NTKYHqvnLTA6YEGg7"
        }}>{`Kensington Palace`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3cbNE5mfhLavtefi8"
        }}>{`London Eye`}</a>{` - The cars are shared with others and aren't private. The glass makes it really hard to take photos. It looks better from afar.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HUUKBYTSzu5GAd6L6"
        }}>{`Natural History Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fSZZwiMQQ1ofxEPEA"
        }}>{`Tate Modern`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rRDf37H8khH5cD417"
        }}>{`Tower Bridge`}</a>{` - Looks better at night`}</li>
    </ul>
    <h4 {...{
      "id": "excursions"
    }}>{`Excursions`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/cxgNLhi8i8yPdjY56"
        }}>{`Stonehenge`}</a>{` - Roped off but still a cool sight`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AsyMbWEAFJZZoiPAA"
        }}>{`The Circus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/pq9iDyH6m18b1Mmi9"
        }}>{`The Roman Baths`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1WHCDNkwDMkCzab29"
        }}>{`Windsor Castle`}</a>{` - Tour recommended for context. They also had changing of the guard here.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      